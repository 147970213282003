import { useRouter } from 'vue-router'
import { onMounted, onUnmounted } from 'vue'

export function useBackNavigation(callback: () => void) {
    const router = useRouter()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    router.beforeEach((to, from, next) => {
        const isBackNavigation = !!(
            window.history.state && window.history.state.back
        )

        if (isBackNavigation) {
            console.log('Пользователь нажал кнопку "Назад"')
            callback()
        }

        next()
    })

    const handlePopState = () => {
        console.log(
            'Пользователь нажал "Назад" или "Вперед" в браузере'
        )
        // callback()
    }

    onMounted(() => {
        window.addEventListener('popstate', handlePopState)
    })

    onUnmounted(() => {
        window.removeEventListener('popstate', handlePopState)
    })
}
